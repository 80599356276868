<template>
    <section>
        <cargando v-if="cargando" />
        <div class="row mx-0">
            <div class="panel-categories logged overflow-auto scroll-none">
                <div class="row mx-0 my-1 j-center">
                    <div class="col-10 f-18 f-600 pt-3 pb-1">
                        <el-tooltip content="va la madre">
                            Categorías
                        </el-tooltip>
                    </div>
                </div>
                <!-- <el-tree ref="tree" accordion :data="categorias" :props="defaultProps" :filter-node-method="filterNode" /> -->
                <div v-for="(cat, index) in categorias" :key="index" slot="reference" class="row mx-0 j-center mb-2 cr-pointer" @click="categoriaActiva = cat.id; alertaSeguridad(cat.adultos)">
                    <div class="col-10 px-0 border-bottom d-middle pb-1">
                        <div :class="`${categoriaActiva == cat.id ? 'bg-general' : ''} br-20`" style="width:8px;height:40px;" />
                        <div v-if="cat.id > 0" class="w-100">
                            <!-- <el-popover placement="right-start" width="220" trigger="click">
                                <div v-for="(sub, key) in cat.subCategorias" :key="key" class="row j-center mb-2 cr-pointer" @click="categoriaActiva = cat.id; irASubCategoria(sub.id)">
                                    <div class="col-11 px-0 border-bottom d-middle py-2">
                                        <div class="col px-2 f-13 f-500">
                                            Sub {{ sub.nombre }}
                                        </div>
                                    </div>
                                </div>
                                <div slot="reference" class="d-middle justify-content-between">
                                    <div class="col px-2 f-13 f-500">
                                        Main => {{ cat.nombre }}
                                    </div>
                                    <i class="icon-right-open f-14 text-gris2" />
                                </div>
                            </el-popover> -->
                            <div class="d-middle justify-content-between">
                                <div class="col px-2 f-13 f-500" @click="categoriaActiva = cat.id; irASubCategoria(sub.id)">
                                    {{ cat.nombre }}
                                </div>
                                <el-tooltip placement="right-start" effect="light" popper-class="tooltip-subcategorias" :visible-arrow="false">
                                    <template #content>
                                        <div v-for="(sub, key) in cat.subCategorias" :key="key" class="row sub-nombre mx-0 j-center mb-2 cr-pointer" @click="categoriaActiva = cat.id; irASubCategoria(sub.id)">
                                            <div class="col-12 px-0 border-bottom d-middle py-2">
                                                <div class="px-2 f-13 f-500" style="min-width: 200px !important;">
                                                    {{ sub.nombre }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <i class="icon-right-open f-14 text-gris2" />
                                </el-tooltip>
                            </div>
                        </div>
                        <div v-else>
                            <div class="col px-2 f-13 f-500" @click="categoriaActiva = cat.id; irASubCategoria(sub.id)">
                                {{ cat.nombre }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
              
        <modal ref="modalAlertaEdad" titulo="" cancelar="Cancelar" :adicional="nombreBtn" @adicional="aceptarModal">
            <div v-if="mostrarEdad == 1" class="row mx-0 j-center">
                <p class="text-center">
                    ¿Eres mayor de edad?
                </p>
            </div>
            <div v-else class="row mx-0 j-center">
                <p class="f-600 my-3">
                    Dinos tu fecha de nacimiento
                </p>
                <div class="col-10">
                    <p class="text-general f-12 pl-3">Fecha de nacimiento</p>
                    <el-date-picker
                    v-model="fechaNac"
                    class="w-100"
                    type="date"
                    placeholder="Seleccionar"
                    />
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import moment from 'moment'
import Categorias from '~/services/categorias/categorias'
import Cliente from '~/services/cliente/cliente'

export default {
    data(){
        return{
            cargando: false,
            mostrarEdad: 1,
            nombreBtn: 'Si',
            fechaNac: '',
            categorias: [],
            categoriaActiva: null,
            screenWidth: null,
            filterText: '',
            defaultProps: {
                children: 'subCategorias',
                label: 'nombre'
            }
        }
    },
    computed:{
        idCategoria(){
            return Number(this.$route.params.idCategoria);
        },
    },
    watch: {
        // filterText(val){
        //     this.$refs.tree.filter(val);
        // }
    },
    mounted(){
        if(this.idCategoria){
            this.categoriaActiva = this.idCategoria;
        }
        this.getCategorias();
        this.screenWidth = window.innerWidth
    },
    methods: {
        filterNode(value, data){
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        async getCategorias(){
            try {
                this.cargando = true;

                const {data} = await Categorias.getCategorias();
                this.categorias = data.categorias;
                if(!this.categoriaActiva){
                    this.categoriaActiva = data.categorias.length > 0 ? data.categorias.find((item) => item.id === 0).id : 0;
                }
            } catch(e){
                this.errorCatch(e)
            } finally{
                this.cargando = false;
            }
        },
        alertaSeguridad(condition){
            if (parseInt(condition) === 1){
                this.mostrarEdad = 1
                this.nombreBtn = 'Si'
                if (this.$usuario.edad < 18){
                    return this.$refs.modalAlertaEdad.toggle();                    
                }
            }
            if(parseInt(this.categoriaActiva) === 0){
                this.$router.push({name:'home.tienda'});
            } else {
                this.$router.push({name:'categoria.ver', params:{idCategoria: this.categoriaActiva}});
            }
        },
        irASubCategoria(idSubCategoria){
            this.$router.push({name:'categoria.ver.subcategoria', params:{idCategoria: this.categoriaActiva,idSubCategoria:idSubCategoria}});
        },
        aceptarModal(){
            if (this.nombreBtn == 'Si'){
                this.nombreBtn = 'Aceptar'
                this.mostrarEdad = 2
                this.fechaNac = ''
            }else if(this.nombreBtn == 'Aceptar'){
                if (this.fechaNac == '' || this.fechaNac == null){
                    this.notificacion('Advertencia','Por favor seleccione la fecha de nacimiento','warning')
                    return
                }
                this.edadAprovada()
                this.$refs.modalAlertaEdad.toggle();
            }
        },
        async edadAprovada(){
            await Cliente.putFechaNac({fecha:moment(this.fechaNac).format('YYYY-MM-DD'), idUserCliente: this.$usuario.id});            
            this.notificacion('Mensaje','Fecha nacimiento actualizada con correctamente','warning') 
            await this.$store.dispatch('auth/consultarUsuario')
        },
    }
}
</script>
<style lang="scss" scoped>

.panel-categories{
  height: calc(100vh - 80px);
  width: 245px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px #00000014;
  position: absolute;
  z-index: 110;
  @media (min-width: 300px) and (max-width: 1024px) {
      height: calc(100vh - 96px);
  }
  @media (min-width: 300px) and (max-width: 1000px) {
      //display: none !important;
  }
}

.img-categories{
  box-shadow: 0px 3px 6px #00000029;
}

.tabs-mobile{
  display: none !important;
}

.banner-invitado{
    height: 113px;
    color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
    background-image: linear-gradient(to right, #612AD5,#9F4BFF, #F9C7FF,  #612AD5);
    .btn-registrarse{
        background-color: #FFFFFF;
        color: #612AD5;
        font-weight: 500;
        max-width: 182px;
        height: 44px;
        border-radius: 12px;
    }
}

.card-modificado{
    border: 1px solid #FF5806;
    background-color: #FFF3EA;
    //width: 312px;
    border-radius: 8px;
    height: 78px;
    .text-pedidos{
        height: 47px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
    }
    .btn{
        display: flex;
        align-items: center;
        color: #FFFFFF;
        height: 32px;
        border-radius: 12px;
        width: 100%;
        background-color: #FF5806;
    }
}

@media (min-width: 300px) and (max-width: 815px) {

  .title-category{
    display: none !important;
  }  

  .img-categories{
    width: 213px;
    height: 86px;
  }
  
  .tabs-mobile{
    display: block !important;
  }

  .titles{
    font-size: 17px !important;
    font-weight: 500 !important;
  }

  .spacer{
    display: none !important;
  }

  .text-arrow{
    display: none !important;
  }
  .like-bar{
    display: none !important;
  }

  
}


@media (min-width: 300px) and (max-width: 1024px) {
    .col.home-page{
          height: calc(100vh - 106px) !important;
      }
}

</style>
